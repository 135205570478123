const userStorageKey = `${window.VUE_STORAGE_KEY_PREFIX}-user`

export const useStorage = () => {
  function getUser(){
    return localStorage.getItem(userStorageKey)
  }
  
  function setUser(data){
    localStorage.setItem(userStorageKey, JSON.stringify(data))
  }

  function removeUser(){
    localStorage.removeItem(userStorageKey)
  }

  return {
    getUser, setUser, removeUser
  }
}