import { createRouter, createWebHistory } from 'vue-router'
import { useMiddleware } from './middlewares'
const { MIDDLEWARES } = useMiddleware()

const routes = [
  /**************************************************************
   *                        Home Page                           *
   **************************************************************/
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeView.vue'),
  },
  
  /**************************************************************
   *                      Authentication                        *
   **************************************************************/
  {
    path: '/inscription',
    name: 'RegisterView',
    component: () => import('../views/auth/RegisterView.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPasswordView',
    component: () => import('../views/auth/ForgotPasswordView.vue')
  },
  {
    path: '/redirect',
    name: 'RedirectView',
    component: () => import('../views/auth/RedirectView.vue')
  },
  {
    path: '/validation',
    name: 'ValidationView',
    component: () => import('../views/auth/ValidationView.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.VALIDATION] }
  },
  {
    path: '/activation',
    name: 'ActivationView',
    component: () => import('../views/auth/ActivationView.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.ACTIVATION] }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/auth/Logout.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH] }
  },
  
  /***************************************************************
   *                        Client                               *
   ***************************************************************/
  {
    path: '/user/welcome',
    name: 'WelcomeView',
    component: () => import('../views/user/WelcomeView.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.USER] }
  },
  {
    path: '/user/account',
    name: 'UserAccount',
    component: () => import('../views/user/MyAccountView.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.USER] }
  },
  {
    path: '/user/preferences',
    name: 'UserPreferences',
    component: () => import('../views/user/UserPreferences.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.USER] }
  },
  {
    path: '/user/subscription',
    name: 'UserSubscription',
    component: () => import('../views/user/subscription/UserSubscription.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.USER] }
  },
  {
    path: '/user/subscription/success',
    name: 'UserSubscriptionSuccess',
    component: () => import('../views/user/subscription/SubscriptionSuccess.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.USER, MIDDLEWARES.REFRESH_USER] }
  },
  {
    path: '/user/subscription/failure',
    name: 'UserSubscriptionFailure',
    component: () => import('../views/user/subscription/SubscriptionFailure.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.USER] }
  },
  {
    path: '/user/subscription/update',
    name: 'UserSubscriptionUpdate',
    component: () => import('../views/user/subscription/SubscriptionUpdate.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.USER] }
  },
  {
    path: '/user/subscription/topup/success',
    name: 'UserTopupSuccess',
    component: () => import('../views/user/subscription/TopUpSuccess.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.USER, MIDDLEWARES.REFRESH_USER] }
  },
  {
    path: '/user/subscription/topup/failure',
    name: 'UserTopupFailure',
    component: () => import('../views/user/subscription/TopUpFailure.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.USER] }
  },
  {
    path: '/user/my-wine-cellar',
    name: 'MyWineCellar',
    component: () => import('../views/user/MyWineCellarView.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.USER] }
  },
  {
    path: '/user/cgv',
    name: 'CgvView',
    component: () => import('../views/user/Cgv.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.USER] }
  },

  /**************************************************************
   *                        Admin                               *
   **************************************************************/

  // inscription
  {
    path: '/admin/inscription',
    name: 'InscriptionView',
    component: () => import('../views/admin/clients/InscriptionView.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.ADMIN] }
  },

  // liste de demandes d'inscription
  {
    path: '/admin/demandes',
    name: 'DemandesView',
    component: () => import('../views/admin/clients/DemandesView.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.ADMIN] }
  },

  // clients
  {
    path: '/admin/clients',
    name: 'ClientsView',
    component: () => import('../views/admin/clients/ClientsView.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.ADMIN] }
  },
  {
    path: '/admin/clients/:userId',
    name: 'ClientView',
    component: () => import('../views/admin/clients/ClientProfileView.vue'),
    props: true,
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.ADMIN, MIDDLEWARES.NEED_USER_DATA] }
  },
  {
    path: '/admin/clients/:userId/cave',
    name: 'ClientCaveView',
    component: () => import('../views/admin/clients/ClientCaveView.vue'),
    props: true,
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.ADMIN, MIDDLEWARES.NEED_USER_DATA] }
  },
  {
    path: '/admin/clients/:userId/cave/addProduct',
    name: 'AddProductToClientView',
    component: () => import('../views/admin/clients/AddProductToClientView.vue'),
    props: true,
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.ADMIN, MIDDLEWARES.NEED_USER_DATA, MIDDLEWARES.RESET_PRODUCT_SEARCH_DATA] }
  },

  // catégories
  {
    path: '/admin/category',
    name: 'ProductsCategoriesView',
    component: () => import('../views/admin/products/ManageCategoriesView.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.ADMIN, MIDDLEWARES.RESET_PRODUCT_SEARCH_DATA] }
  },

  // produits
  {
    path: '/admin/productslist',
    name: 'ProductsListView',
    component: () => import('../views/admin/products/SearchProductView.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.ADMIN, MIDDLEWARES.RESET_PRODUCT_SEARCH_DATA] },
  },
  {
    path: '/admin/productslist/:productId',
    name: 'ProductDetailsView',
    component: () => import('../views/admin/products/ProductDetailsView.vue'),
    props: true,
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.ADMIN, MIDDLEWARES.NEED_PRODUCT_DATA] }
  },
  {
    path: '/admin/addproduct',
    name: 'AddProductView',
    component: () => import('../views/admin/products/CreateProductView.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.ADMIN] }
  },

  // Arrivages produits
  {
    path: '/admin/arrivals/list',
    name: 'ArrivalsListView',
    component: () => import('../views/admin/arrivals/ArrivalsListView.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.ADMIN, MIDDLEWARES.NEED_ARRIVAL_DATA] },
  },
  {
    path: '/admin/arrivals/supplier',
    name: 'SupplierView',
    component: () => import('../views/admin/arrivals/SelectSupplierView.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.ADMIN] },
  },
  {
    path: '/admin/arrivals/draft/:draftId',
    name: 'ArrivalDraftView',
    component: () => import('../views/admin/arrivals/ArrivalDraftView.vue'),
    props: true,
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.ADMIN, MIDDLEWARES.NEED_ARRIVAL_DATA] },
  },

  // arrivage au détail
  {
    path: '/admin/arrivals/:arrivalId',
    name: 'ClosedArrivalView',
    props: true,
    component: () => import('../views/admin/arrivals/ArrivalDetailsView.vue'),
    meta: { middlewares: [MIDDLEWARES.AUTH, MIDDLEWARES.ADMIN, MIDDLEWARES.NEED_ARRIVAL_DATA] },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// met en place les middlewares
// ~> @/router/middlewares.js

router.beforeEach(async (to, from) => {

  window.scrollTo({top: 0, behavior: "smooth"})

  if(to.meta && to.meta.middlewares && to.meta.middlewares.length) {
    for await (const middleware of to.meta.middlewares){
      const middlewareResponse = await middleware(to, from)

      if(middlewareResponse === false) return false
      else if(middlewareResponse) return middlewareResponse
    }
  }
})

export default router
