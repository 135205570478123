export const useDraft = () => {
  function unzipDraft(draft) {
    const parsedData = JSON.parse(draft.draft)
    const parsedDraft = {
      ...draft,
      ...parsedData,
      isDraft: true
    }
    delete parsedDraft.draft
    return parsedDraft
  }

  function zipDraft(draft) {
    return JSON.stringify(draft)
  }

  return { unzipDraft, zipDraft}
}
