import { format as fnsFormat, parseISO, addYears, isBefore, isAfter } from 'date-fns'
import { fr } from 'date-fns/locale/index.js'

export const useDate = () => {

  function format(date, format, locale = false) {
    let toLocale = locale
    if (locale == "fr") toLocale = { locale: fr }
    try{
      return fnsFormat(new Date(date), format, toLocale)
    }
    catch(e){
      console.error("[useDate] - Invalid date format :", date, e)
      return ""
    }
  }

  function isAdult(birthDate) {
    const date = parseISO(birthDate)
    const minDate = addYears(new Date(), -18)
    return isBefore(date, minDate)
  }

  function isFromLastMonth(dateToCheck){
    const today = new Date()
    const createdOn = new Date(dateToCheck)
    const lastMonth = today.setMonth(today.getMonth() - 1)

    return createdOn > lastMonth
  }

  function isTodayBetween(startDate, endDate) {
    const today = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    return isAfter(today, start) && isBefore(today, end);
  }

  return {
    format,
    isAdult,
    isFromLastMonth,
    isTodayBetween
  }
}