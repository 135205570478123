import { defineStore } from 'pinia'
import { ref } from 'vue'

import api from '@/composables/api/useApi'
import { useRequest } from '@/composables/api/useRequest'

const categories = ref([])
const category = ref(null)

export const useCategoryStore = defineStore('category', () => {

  async function searchCategories(categoryInput) {
    if (categoryInput.length > 1) {
      const res = await api.get('CategoryManagement/category', { params: { search: categoryInput } })
      categories.value = res.data
    }
    else {
      resetCategoryStore()
    }
  }

  function selectCategory(selectedCategory) {
    categories.value = []
    category.value = selectedCategory
  }

  async function createCategory(data) {
    try {
      await useRequest(api.post('CategoryManagement/category', data))
    }
    catch(error) {
      Promise.reject(error)
    }
  }
  
  async function editCategoryFullname(categoryFullname) {
    try {
      const categoryId = category.value.categoryId
      await useRequest(api.put('CategoryManagement/category/' + categoryId, categoryFullname))
    }
    catch(error) {
      Promise.reject(error)
    }
  }

  function resetCategoryStore() { 
    categories.value = []
    category.value = null
  }

  return {
    categories, category,
    searchCategories, selectCategory, createCategory, editCategoryFullname,
    resetCategoryStore
  }
})