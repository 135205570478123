<template>
  <header class="lateral-header admin-header">
    <button class="retract-btn" @click="admin.toggleMenuView()">
        <span class="icon icon-simple-arrow-rounded"></span>
      </button>
      
    <nav class="mobile-main-nav hide-large-screen hide-tablet-landscape" :class="{ 'open': openMenuMobile }">
      <button class="menu-burger--btn" @click="openMenuMobile = !openMenuMobile" aria-label="Ouvrir le menu">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </nav>
     
    <section class="lateral-menu column" :class="{'retracted' : admin.hideFullMenu}">
      <nav class="mobile-main-nav hide-large-screen hide-tablet-landscape" :class="{ 'open': openMenuMobile }">
        <button class="menu-burger--btn" @click="openMenuMobile = !openMenuMobile" aria-label="Fermer le menu">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </nav>
     
      <!--:src="admin.hideFullMenu ? '@/assets/img/logos/logo-only-chair.svg' : 'https://storage.googleapis.com/cotevins-strapi-assets/logo_kapital_vins_admin_7e488f12f2/logo_kapital_vins_admin_7e488f12f2.svg'"-->
      <img class="logo need-mB3" v-show="!admin.hideFullMenu"
        src="https://storage.googleapis.com/cotevins-strapi-assets/logo_kapital_vins_admin_7e488f12f2/logo_kapital_vins_admin_7e488f12f2.svg"
        alt="" />
      <img class="mini logo need-mB3" v-show="admin.hideFullMenu"
        src="@/assets/img/logos/logo-only-chair.svg"
        alt="" />
      <p class="cartouche bg-pink brown bold capitalize fit-content need-mL need-mB3" :class="{'hide' : admin.hideFullMenu}">Administrateur</p>

      <div class="lateral-menu-content column between flex1">
        <nav>
          <ul class="column need-mT">
            <router-link to="/admin/inscription" class="nav--item">
              <span class="icon icon-user-plus need-mR-5"></span>
              <span :class="{'hide' : admin.hideFullMenu}">Inscription</span>
            </router-link>
            <router-link to="/admin/demandes" class="nav--item">
              <span class="icon icon-user-check need-mR-5"></span>
              <span :class="{'hide' : admin.hideFullMenu}">Demandes d'inscription</span>
            </router-link>
            <router-link to="/admin/clients" class="nav--item"
              :class="clientsHaveSubPages ? 'sub-active' : ''">
              <span class="icon icon-users need-mR-5"></span>
              <span :class="{'hide' : admin.hideFullMenu}">Liste des clients</span>
            </router-link>
            <router-link to="/admin/productslist" class="nav--item"
              :class="productHaveSubPages ? 'sub-active' : ''">
              <span class="icon icon-list need-mR-5"></span>
              <span :class="{'hide' : admin.hideFullMenu}">Liste des produits</span>
            </router-link>
            <router-link to="/admin/addproduct" class="nav--item">
              <span class="icon icon-plus-square need-mR-5"></span>
              <span :class="{'hide' : admin.hideFullMenu}">Nouveau produit</span>
            </router-link>
            <router-link to="/admin/category" class="nav--item">
              <span class="icon icon-cave-unselect need-mR-5"></span>
              <span :class="{'hide' : admin.hideFullMenu}">Gestion des catégories</span>
            </router-link>
            <router-link to="/admin/arrivals/list" class="nav--item">
              <span class="icon icon-download need-mR-5"></span>
              <span :class="{'hide' : admin.hideFullMenu}">Arrivages produits</span>
            </router-link>
          </ul>
        </nav>

        <div class="need-mL15 need-mT4 need-mB2">
          <p class="uppercase bold fontsize-8 need-mB2" :class="{'hide' : admin.hideFullMenu}">En plus</p>
          <nav>
            <a class="link semibold" :href="hiboutikUrl" target="_blanck">
              <span class="hiboutik-link">
                <img src="/img/icons/logo-hiboutik.svg" class="icon logo-hiboutik"/>
                <span :class="{'hide' : admin.hideFullMenu}">Hiboutik</span>
                <!-- <span class="icon icon-external-link fontsize12 bold need-mL-25"></span> -->
              </span>
            </a>
            <a class="link row align-center semibold need-mT" :href="helpDocumentationUrl" target="_blanck">
                <span class="icon icon-info-rounded fontsize13 need-mR-5"/>
                <span :class="{'hide' : admin.hideFullMenu}">Aide</span>
                <!-- <span class="icon icon-external-link fontsize12 bold need-mL-25"></span> -->
            </a>
            <p class="dev-version" :class="{'position' : admin.hideFullMenu}"><span :class="{'hide' : admin.hideFullMenu}">Version : </span>{{ this.version }}</p>
            <button class="link row align-center need-mT" @click="popup.open('LogoutPopup')">
              <span class="icon bold fontsize12 icon-logout need-mR-5"></span>
              <span class="semibold" :class="{'hide' : admin.hideFullMenu}">Se déconnecter</span>
            </button>
          </nav>
        </div>
      </div>
    </section>
  </header>
</template>

<script>

import { usePopup } from "@/composables/materials/usePopup"
import { useAdminStore } from '@/pinia/adminStore'

export default {
  name: "AdminHeader",
  setup() {
    const popup = usePopup()
    const admin = useAdminStore()
    return { admin, popup }
  },
  data() {
    return {
      hiboutikUrl: window.VUE_HIBOUTIK_URL,
      helpDocumentationUrl: window.VUE_HELP_DOCUMENTATION,
      openMenuMobile: false,
      version:0
    }
  },
  async beforeMount() {
    
    const pjson = require("../../../package.json")
    console.log(pjson.version)
    this.version = pjson.version
  
  },
  computed: {
    clientsHaveSubPages(){
      if(this.$route.matched[0].path == '/admin/clients/:userId' || this.$route.matched[0].path == '/admin/clients/:userId/cave' || this.$route.matched[0].path == '/admin/clients/:userId/cave/addProduct') return true
      else return false
    },
    productHaveSubPages(){
      if(this.$route.matched[0].path == '/admin/productslist/:productId' ) return true
      else return false
    }
  }
}
</script>

<style scoped>
.hiboutik-link {
  display: flex;
}
.logo-hiboutik {
  height: 1.2rem;
  margin-right: .5rem;
}
</style>