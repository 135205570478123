import { ref } from 'vue'

/**
 * ------------------------------
 * DONNÉES ACCESSIBLES DANS L'APP
 * ------------------------------
 */

// -> si aucune popup n'est ouverte = false
// -> si une popup s'ouvre = { name: "popupName", data: {...} }
const current = ref(false)

export const usePopup = () => {

  /**
   * Ouvre une popup rangée dans le dossier @/components/materials/popups
   * @param {*} name - Le nom de la popup à ouvrir
   * @param {*} data - (facultatif) Props à passer
   */
  function open(name, data = {}) {
    current.value = { name, data }
    document.body.style.overflow = 'hidden'
    // Ajoute props passées via le paramètre data
    if(Object.entries(data).length){
      for(const [key, value] of Object.entries(data)){
        current.value.data[key] = value
      }
    }
  }

  /**
   * Ferme la popup actuellement ouverte
   */
  function close() {
    current.value = false
    document.body.style.overflow = 'visible'
  }

  /**
   * Récupère les props passées en paramètre avec open()
   */
  function getProps(){
    return current.value.data
  }

  return {
    current,
    getProps,
    open,
    close,
  }
}