<template>
  <div>
    <Suspense>
      <router-view />
    </Suspense>
    
    <Toaster />
    <Suspense>
      <Popup />
    </Suspense>
    <Loader />


    <aside class="popup--container--center blur" v-if="updateExists">
        <div class="popup--content column align-center justify-center">
          <p class="fontsize15 semibold need-mB2">Mise à jour disponible </p>
          <button @click="refreshApp" class="btn bg-classic-green">Mettre à jour</button>
        </div>
    </aside>

    
  </div>
</template>


<script>
import update from './mixins/update'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

export default {
  async beforeMount() {
    if (window.VUE_ENABLE_DDOG == "true") {
      const pjson = require("../package.json")
      this.enableDatadog(pjson.version)
    }
  },
  mixins: [update],
  methods: {
    enableDatadog(version) {
      datadogRum.init({
        applicationId: "4264dca1-bc04-47ae-b4d5-c2e79a7ed098",
        clientToken: "pubedaae48cdae229737440bcd827905bcf",
        site: "datadoghq.eu",
        service: "kapital-vins",
        env: window.VUE_DDOG_ENV,
        version: version,
        sampleRate: 100,
        premiumSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "allow",
        forwardErrorsToLogs: true,
        forwardConsoleLogs: "all",
        allowedTracingOrigins: ["https://app.cote-vins.fr/","https://app.dev.cote-vins.fr/"]

      });
      datadogLogs.setLoggerGlobalContext({ env: window.VUE_DDOG_ENV });
      datadogRum.startSessionReplayRecording();
    }
  }
}
</script>

<style lang="scss">
@import "assets/scss/base/_colors.scss";
@import "assets/scss/base/_fonts.scss";
@import "assets/scss/base/_icomoon.scss";
@import "assets/scss/base/_mediaqueries.scss";
@import "assets/scss/base/_normalize.scss";

@import "assets/scss/common/_mixins.scss";
@import "assets/scss/common/_common-elements.scss";
@import "assets/scss/common/_common-layout.scss";
@import "assets/scss/common/_common-styles.scss";
@import "assets/scss/common/_general-layout.scss";
@import "assets/scss/common/_simple-layout.scss";
@import "assets/scss/common/_specific-elements.scss";


@import "assets/scss/general/_forms.scss";
@import "assets/scss/general/_popups.scss";
@import "assets/scss/general/_toasters.scss";


@import "assets/scss/pages/_admin-client.scss";
@import "assets/scss/pages/_admin.scss";
@import "assets/scss/pages/_loggedUser.scss";


@import "assets/scss/_header-footer.scss";
@import "assets/scss/main.scss";
</style>