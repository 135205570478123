import axios from "axios";
import { useAuthStore } from '@/pinia/authStore'

// AXIOS INSTANCE
const instance = axios.create({
  baseURL: window.VUE_APP_API_URL,
  headers: {"Content-Type": "application/json"},
  withCredentials: true
})

// REQUEST INTERCEPTORS
// request success
const requestSuccessInterceptor = (config) => {
  const auth = useAuthStore()
  if (auth.user)
    config.headers["Authorization"] = 'Bearer ' + auth.user.token
  return config
}

// request error
const requestErrorInterceptor = (error) => {
  return Promise.reject(error);
}

// RESPONSE INTERCEPTORS
// response success
const responseSuccessInterceptor = (response) => {
  return response
}
// response error
const responseErrorInterceptor = async (error) => {
  // try refresh auth token
  const originalConfig = error.config;
  if ((originalConfig.url !== "/authentication/") && error.response){
    console.error('ERR...')
    if (error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true
      const auth = useAuthStore()
      try {
        const res = await auth.refresh()
        console.info(res)
        return requestSuccessInterceptor(originalConfig)
      }
      catch (error) {
        auth.logout()
        return Promise.reject(error)
      }
    }
  }

  return Promise.reject(error);
}

// APPLY INTERCEPTORS
instance.interceptors.request.use(requestSuccessInterceptor, requestErrorInterceptor)
instance.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor)

export default instance;