import { useLoader } from '@/composables/materials/useLoader'

const defaultOptions = {
  chainRequest: true
}

export const useRequest = async (requestData, options = defaultOptions) => {

  const {startLoading, endLoading} = useLoader()
  let response = null

  try {
    startLoading()
    if(requestData instanceof Promise){
      response = await requestData
    }
    else {
      response = await Promise.all(requestData)
    }

    if(options.chainRequest){
      endLoading()
    }

    return response
  }
  catch(error){
    endLoading()
    return Promise.reject(error)
  }
}