import { defineStore } from 'pinia'
import { useRequest } from '@/composables/api/useRequest'
import { useLoader } from '@/composables/materials/useLoader'

import api from '@/composables/api/useApi'
import router from '@/router'


import { useDate } from '@/composables/app/useDate'
const { format } = useDate()

export const useUserStore = defineStore('user', {

  state: () => ({
    hasData: false,
    user: null,
  }),

  actions: {
    /** 
     *  Fetch user data
     */
    async getData() {
      const res = await useRequest([
        //api.get("users"),
        api.get("users/details"),
        api.get('usersubscription'),
        api.get('userStripePayment'),
        api.get('account'),
        api.get('caveItems'),
        api.get('subscription')
      ])

      const [
        { data: userData },
        { data: subscription },
        { data: payments },
        { data: balance },
        { data: items },
        { data: subscriptionMeta }
      ] = res

      this.user = {
        data: userData,
        preferences: userData.userPreferences ? userData.userPreferences : false,
        //address: userData.addresses.length ? userData.addresses[0] : false,
        address: userData.addresses ? userData.addresses.length ? userData.addresses.filter((addresse) => addresse.isDefault)[0] : false : false,
        subscription,
        payments,
        balance: balance.solde,
        items,
        meta: { subscription: subscriptionMeta[0] }
      }

      // sort payments by date
      this.user.payments = this.user.payments
        .map((p) => ({
          ...p,
          paymentDate: new Date(p.paymentDate).getTime()
        }))
        .sort((a, b) => b.paymentDate - a.paymentDate)

      this.hasData = true

     
    },

    /** 
     *  Preferences
     */
    async searchCity(query) {
      try {
        return await api.post("CitySearch/city", { entry: query })
      }
      catch(error) {
        return Promise.reject(error)
      }
    },

    async sendPreferences(preferences, address) {
      const { endLoading } = useLoader()

      try {
        const res = await useRequest([
          api.post(`userPreferences`, preferences),
          api.post(`userAddress`, address),
        ], { chainRequest: false })

        if (router.currentRoute.value.path.includes("user/preferences")) {
          router.push('/user/account')
        }
        return res
      }
      catch (error) {
        return Promise.reject(error)
      }
      finally {
        endLoading()
      }
    },

    async updatePreferences(preferences) {
      try {
        let response = await useRequest(api.put(`userPreferences`, preferences))
        this.user.preferences = response.data.userPreferences
      }  catch (error) {
        return Promise.reject(error)
      }
      

    },
    


    async sendTmpCode(email) {
      await useRequest(api.post(`password/forget/step/1`,{email}))
    },
    async sendNewPasswordFromTempCode(reqBody) {
      return await useRequest(api.post(`password/forget/step/2`,reqBody))
    },

    async getPreferences() {
      const preferences = await useRequest(api.get(`userPreferences`))
      if (preferences.data) this.user = { preferences: preferences.data }
    },


    /** 
     *  Subscriptions
     */
    async createSubscription(blockCount) {
      const subscription = {
        numberOfBlocks: blockCount,
        stripeProductId: this.user.meta.subscription.stripeId,
        callBackSuccess: `${window.location.origin}/user/subscription/success`,
        callBackFailure: `${window.location.origin}/user/subscription/failure`,
      }

      try {
        const res = await useRequest(api.post('usersubscription', subscription))
        window.location.href = res.data.stripeUrl
      }
      catch (error) {
        return Promise.reject(error)
      }
    },

    async updateUser(values) {
      var newValues = {   
        "firstname": values.firstname,
        "lastname": values.lastname,
        "cellphone": values.cellphone,
        "email": values.email,
        "birthday": format(new Date(values.birthday),"yyyy-MM-dd")
      }

        
      if(values.address.addressLine1) newValues.address1 = values.address.addressLine1
      if(values.address.addressLine2) newValues.address2 = values.address.addressLine2
      if(values.address.zipCode) newValues.zipCode = values.address.zipCode
      if(values.address.city) newValues.city = values.address.city

      try {
        await useRequest(api.put('users', newValues))
      }
      catch (error) {
        return Promise.reject(error)
      }

    },

    async updateSubscription(blockCount) {
      try {
        const subscription = {
          numberOfBlocks: blockCount,
          stripeProductId: this.user.meta.subscription.stripeId,
          subscriptionId: this.user.subscription.id
        }
        await useRequest(api.put('usersubscription', subscription))
        await this.getData()
      }
      catch (error) {
        return Promise.reject(error)
      }
    },

    async topUp(amount) {
      try {
        const data = {
          amount: amount,
          callBackSuccess: `${window.location.origin}/user/subscription/topup/success`,
          callBackFailure: `${window.location.origin}/user/subscription/topup/failure`,
        }
        const res = await useRequest(api.post('topup', data ))
        window.location.href = res.data.paymentUrl
      }
      catch (error) {
        return Promise.reject(error)
      }
    },

    async cancelSubscription() {
      try {
        await useRequest(api.delete('usersubscription', { data: { stripeSubscriptionId: this.user.subscription.id } }))
        await this.getData()
      }
      catch (error) {
        return Promise.reject(error)
      }
    },

    resetStore() {
      this.hasData = false
      this.user = null
    }
  },

  getters: {
    /** 
     *  Processed user data
     */
    hasPreferences: (state) => {
      return state.user && state.user.preferences
    },

    userProcedure: (state) => {
      switch (state.user.preferences.procedureChoice) {
        case 'foryou':
          return "Kapital Vins sélectionne seul"
        case 'withyou':
          return "Kapital Vins sélectionne avec la personne"
        case 'myself':
          return "La personne vient choisir en magasin"
        default:
          return "Non renseigné"
      }
    },
  }
})