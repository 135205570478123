import { createApp, defineAsyncComponent } from 'vue'
import { createPinia } from 'pinia'
import App from '@/App.vue'
import router from '@/router'
import '@/registerServiceWorker'

import UserHeader from '@/components/layout/UserHeader.vue'
import AdminHeader from './components/layout/AdminHeader.vue'

const pinia = createPinia()

createApp(App)
    .component('UserHeader', UserHeader)
    .component('AdminHeader', AdminHeader)
    .component('Pagination', defineAsyncComponent(() => import('./components/materials/PaginationComponent.vue')))
    .component('Popup', defineAsyncComponent(() => import('./components/materials/PopupComponent.vue')))
    .component('Toaster', defineAsyncComponent(() => import('./components/materials/ToasterComponent.vue')))
    .component('Loader', defineAsyncComponent(() => import('./components/materials/LoaderComponent.vue')))
    .use(pinia)
    .use(router)
    .mount('#app')
