import { defineStore } from 'pinia'
import api from '@/composables/api/useApi'
import { useRequest } from '@/composables/api/useRequest'

export const useAdminStore = defineStore('admin', {
  state: () => ({
    hasData: false,

    users: [],
    user: null,
    waitingUsers: [],
    
    arrivals: [],
    hideFullMenu:false,
    TVA: [],
  }),

  actions: {
    async getData() {
      const res = await useRequest([
        api.get('usersAdministration/details'),
        api.post('usersAdministration/search', { getActivationWaiting: true, getValidationWaiting: true }),
        api.get('productmanagement/vat')
      ])

      const [
        { data: users },
        { data: waitingUsers },
        { data: TVA },
      ] = res
      
      this.users = users
      this.waitingUsers = waitingUsers
      this.TVA = Object.entries(TVA).map(([index, rate]) => ({
        index: Number(index),
        rate: rate.includes('%') ? rate : rate + "%",
        multiplier: 1 + Number(rate.replace("%", "")) / 100
      }))
      this.hasData = true
    },

    async setUser(userId) {
      const userResponse = await useRequest([
        api.get(`usersAdministration/${userId}/details`),
        api.get(`userSubscription/${userId}`),
        api.get(`userStripePayment/${userId}`),
        api.get(`account/${userId}`),
        api.get(`caveItems/${userId}`),
      ])

      const [
        { data: userData },
        { data: subscription },
        { data: payments },
        { data: balance },
        { data: items },
      ] = userResponse

      this.user = {
        data: userData,
        preferences: userData.userPreferences ? userData.userPreferences : false,
        address: userData.addresses.length ? userData.addresses[0] : false,
        subscription,
        payments,
        balance: balance.solde,
        items,
      }

      // sort payments by date
      this.user.payments = this.user.payments
        .map((p) => ({
          ...p,
          paymentDate: new Date(p.paymentDate).getTime()
        }))
        .sort((a, b) => b.paymentDate - a.paymentDate)
    },

    // ADMIN ACTIONS

    // InscriptionView
    async createUser(form) {
      try {
        await useRequest(api.post(`usersAdministration`, form))
      }
      catch (error) {
        return Promise.reject(error)
      }
    },

    // DemandesView
    async sendValidationEmail(userId) {
      return await useRequest(api.post(`usersAdministration/${userId}/send/validation`, userId))
    },
    async activateUser(userId) {
      try {
        await useRequest(api.post(`usersAdministration/${userId}/switch/active`, userId))
        this.waitingUsers = this.waitingUsers.filter((u) => u.userId !== userId)
      }
      catch (error) {
        return Promise.reject(error)
      }
    },

    async refuseUser(userId, mailContent) {
      try {
        await useRequest([
          api.post(`usersAdministration/${userId}/send/`, { content: mailContent }),
          api.delete(`usersAdministration/${userId}`, userId)
        ])
        this.waitingUsers = this.waitingUsers.filter((u) => u.userId !== userId)
      }
      catch (error) {
        return Promise.reject(error)
      }
    },

    // ClientsView
    async deleteUser(userId) {
      try {
        await useRequest(api.delete(`usersAdministration/${userId}`, userId))
      }
      catch (error) {
        return Promise.reject(error)
      }
    },


    // A voir si on paramètre aussi l'url du lien contenu dans le mail 'connectionLink'
    async sendFreeEmail(userId, mailContent) {
      try {
        await useRequest(api.post(`usersAdministration/${userId}/send/`, { content: mailContent, connectionLink: '/' }))
      }
      catch (error) {
        return Promise.reject(error)
      }
    },

    async addProductToClientCave(query) {
      try {
        await useRequest([
          api.post('caveitems', query),
          this.setUser(this.user.data.userId)
        ])
      }
      catch(error){
        return Promise.reject(error)
      }
    },

    toggleMenuView() {
      this.hideFullMenu = !this.hideFullMenu
    },
    resetStore() {
      this.hasData = false
      this.users = []
      this.waitingUsers = []
      this.user = null
    },
  }
})
