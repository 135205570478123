import api from '@/composables/api/useApi'

import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useCategoryStore } from '@/pinia/categoryStore'
import { useRequest } from '@/composables/api/useRequest'
import { useLoader } from '@/composables/materials/useLoader'

export const UPDATE_TYPES = {
  CATEGORY: "category",
  NAME: "name",
  PRICES: "prices",
  KV: "kv",
  DATA: "data"
}

const products = ref([])
const product = ref(null)
const stock = ref(null)

export const useProductStore = defineStore('product', () => {
  const { startLoading, endLoading } = useLoader()

  async function searchProducts(search, onlyKv) {

    const { category } = useCategoryStore()

    const params = {
      categoryId: category ? category.categoryId : null,
      search,
      onlyKv
    }

    if(!params.onlyKv) startLoading()
    if(params.search){
      const res = await api.get('ProductManagement/product', { params })
      products.value = res.data
    }
    else if(!params.search && category){
      const res = await api.get('ProductManagement/products', { params })
      products.value = res.data
    }
    if(!params.onlyKv) endLoading()
  }

  async function setProduct(productId){
    try {
      const res = await useRequest([
        api.get(`ProductManagement/product/${productId}`),
        api.get(`ProductManagement/product/${productId}/stock`)
      ])

      const [
        { data: productDetails },
        { data: productStock } 
      ] = res

      product.value = productDetails
      stock.value = productStock
    }
    catch(error){
      return Promise.reject(error)
    }
  }

  async function createProduct(formData) {
    const { category } = useCategoryStore()
    const categoryId = category.categoryId

    const product = {
      ...formData,
      categoryId
    }

    try {
      await useRequest(api.post('ProductManagement/product', product))
    }
    catch(error) {
      return Promise.reject(error)
    }
  }

  async function updateProduct(type, data){
    const { category } = useCategoryStore()
    const productId = product.value.productId
    let request

    switch(type){
      case UPDATE_TYPES.CATEGORY : request = api.put(`productmanagement/product/${productId}/category`, {categoryId: category.categoryId})
        break;
      case UPDATE_TYPES.NAME : request = api.put(`productmanagement/product/${productId}/name`, data)
        break;
      case UPDATE_TYPES.PRICES : request = api.put(`productmanagement/product/${productId}/prices`, data)
        break;
      case UPDATE_TYPES.KV : request = api.put(`productmanagement/product/${productId}/usekv`, data)
        break;
      case UPDATE_TYPES.DATA : request = api.put(`productmanagement/product/${productId}`, data)
        break;
      default : request = false
    }

    if(request){
      try {
        await useRequest(request, {chainRequest: true})
        await useRequest(this.setProduct(productId))
      }
      catch(error){
        return Promise.reject(error)
      }
    }
  }

  function resetProductStore(){
    products.value = []
    product.value = null
    stock.value = null
  }

  return { products, product, stock, searchProducts, setProduct, createProduct, updateProduct, resetProductStore, UPDATE_TYPES }
})