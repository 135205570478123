import { ref } from 'vue'

const loading = ref(false)

export const useLoader = () => {
  function startLoading(){
    loading.value = true
  }

  function endLoading(){
    loading.value = false
  }

  return {
    loading,
    startLoading,
    endLoading
  }
}