import { ref } from 'vue'
import Api from '@/composables/api/useApi'
import { useRequest } from '@/composables/api/useRequest'
import { useDraft } from '@/composables/app/useDraft'
const { unzipDraft, zipDraft } = useDraft()

const arrivalList = ref([])
const draftList = ref([])

export const useArrival = () => {

  /**
   * Handle Arrivals
   */

  async function getData() {
    try {
      const res = await useRequest([
        Api.get('inventories'),
        Api.get('inventorydraft')
      ])

      const [
        {data: arrivals},
        {data: drafts}
      ] = res

      arrivalList.value = arrivals
      draftList.value = drafts.map((a) => unzipDraft(a))
    }
    catch(err) {
      console.error(err)
    }
  }

  async function getArrival(arrivalId) {
    try {
      const res = await useRequest(Api.get('inventory/' + arrivalId))
      return res.data
    }catch(err) {
      console.error(err)
    }
  }

  // -<<•>>-<o>-<<•>>-<o>-<<•>>-<o>-<<•>>-<o>-<<•>>-<o>-<<•>>-<o>-<<•>>-

  /**
   * Handle Arrival Draft
   * ---
   * Note:
   * En recevant le draft de l'API, il faut le "dézipper" (JSON.parse)
   * De même, pour l'envoyer il faut le "zipper" (JSON.stringify)
   */

  // Toujours ajouter un numéro de version
  // Version actuelle : 1.0
  async function createDraft(supplier) {
    try {
      const newDraft = JSON.stringify({
        version: "1.0",
        inventoryInputDate: new Date(),
        supplier: supplier,
        products: []
      })

      const res = await useRequest(Api.post('inventorydraft', { draft: newDraft }))
      return unzipDraft(res.data)
    }
    catch(err) {
      console.error(err)
    }
  }

  // Nécessite d'avoir fetch les données (getData)
  function getDraft(draftId) {
    return draftList.value.find((draft) => draft.inventoryDraftId == draftId)
  }

  async function updateDraft(draftId) {
    try {
      const draft = getDraft(draftId)
      const res = await Api.put('inventorydraft', { draftId: draftId, draft: zipDraft(draft) })
      return unzipDraft(res.data)
    }
    catch(err) {
      console.error(err)
    }
  }

  async function deleteDraft(draftId) {
    try {
      await useRequest(Api.delete('inventorydraft/'+draftId))
      await getData()
    }
    catch(err) {
      console.error(err)
    }
  }

  async function validateDraft(draftId) {
    try {
      const draft = getDraft(draftId)
      const supplierId = draft.supplier.supplierId
      
      // 1) Créer un arrivage
      const res = await useRequest(Api.post('inventory', { supplierId }))
      const arrivalId = res.data.inventoryId
      
      for (const product of draft.products) {
        // 2) Modifier le prix des produits Hiboutik
        const productId = product.productId
        const prices = product.draftData.prices
        await useRequest(Api.put(`productManagement/product/${productId}/prices`, prices))
        
        // 3) Ajouter les produits à l'arrivage créé
        const quantity = product.draftData.quantity
        await useRequest(Api.post(`inventory/${arrivalId}`, {productId, quantity}))
      }
      
      // 4) Valider l'inventaire terminé
      await useRequest(Api.post(`inventory/${arrivalId}/validate`))


      // 5) Supprimer le brouillon
      await useRequest(Api.delete(`inventoryDraft/${draftId}`))
    }
    catch(err) {
      console.error(err)
    }
  }

  // -<<•>>-<o>-<<•>>-<o>-<<•>>-<o>-<<•>>-<o>-<<•>>-<o>-<<•>>-<o>-<<•>>-

  /**
   * Modifier le draft d'arrivage
   * ---
   * Pour éviter la moindre erreur, à chaque modification on l'envoie à l'API
   * puis on retourne à la vue le draft modifié, renvoyé par l'API
   */

  async function addDraftProduct(draftId, product){
    const draft = getDraft(draftId)
    draft.products.push({
      ...product,
      draftData: {
        quantity: 1,
        prices: {
          buyingPrice: product.buyingPrice || 0,
          priceHT: product.priceHT || 0,
          priceTTC: product.priceTTC || 0,
          restaurateursPriceHT: product.restaurateursPriceHT || 0,
          restaurateursPriceTTC: product.restaurateursPriceTTC || 0,
          cavistePriceHT: product.cavistePriceHT || 0,
          cavistePriceTTC: product.cavistePriceTTC || 0,
          shippingPrice: product.shippingPrice || 0,
          taxPrice: product.taxPrice || 0,
        }
      }
    })
    return await updateDraft(draftId)
  }

  async function updateProductData(draftId, productId) {
    const draft = getDraft(draftId)
    const refProduct = await Api.get(`/productmanagement/product/${productId}`)
    const product = draft.products.find((product) => product.productId == productId)
    
    product.draftData.prices = {
      buyingPrice: refProduct.data.buyingPrice || 0,
      priceHT: refProduct.data.priceHT || 0,
      priceTTC: refProduct.data.priceTTC || 0,
      restaurateursPriceHT: refProduct.data.restaurateursPriceHT || 0,
      restaurateursPriceTTC: refProduct.data.restaurateursPriceTTC || 0,
      cavistePriceHT: refProduct.data.cavistePriceHT || 0,
      cavistePriceTTC: refProduct.data.cavistePriceTTC || 0,
      shippingPrice: refProduct.data.shippingPrice || 0,
      taxPrice: refProduct.data.taxPrice || 0,
    }

    product.name = refProduct.data.name

    console.log(product)
    return await updateDraft(draftId)
  }



  async function updateDraftProductPrices(draftId, productId, data) {
    const draft = getDraft(draftId)
    const product = draft.products.find((product) => product.productId == productId)
    product.draftData.prices = data.prices
    return await updateDraft(draftId)
  }

  async function deleteDraftProduct(draftId, productId) {
    const draft = getDraft(draftId)
    draft.products = draft.products.filter((p) => p.productId != productId)
    return await updateDraft(draftId)
  }

  // -<<•>>-<o>-<<•>>-<o>-<<•>>-<o>-<<•>>-<o>-<<•>>-<o>-<<•>>-<o>-<<•>>-

  return {
    arrivalList, draftList,
    getData, getArrival, getDraft,
    createDraft, updateDraft, deleteDraft, validateDraft,
    addDraftProduct, deleteDraftProduct,
    updateDraftProductPrices, updateProductData
  }
}