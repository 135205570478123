<template>
  <header class="lateral-header logged-user-header">

    <nav class="mobile-main-nav hide-large-screen hide-tablet-landscape" :class="{ 'open': openMenuMobile }">
      <button class="menu-burger--btn" @click="toggleMenuMobile()" aria-label="Ouvrir le menu">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </nav>
    <section class="lateral-menu column">
      <nav class="mobile-main-nav hide-large-screen hide-tablet-landscape" :class="{ 'open': openMenuMobile }">
        <button class="menu-burger--btn" @click="toggleMenuMobile()" aria-label="Fermer le menu">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </nav>
      <img class="logo need-mL15 need-mB4"
        src="https://storage.googleapis.com/cotevins-strapi-assets/logo_cotevins_kapitalvins_c6b98c9969/logo_cotevins_kapitalvins_c6b98c9969.svg"
        alt="" />
      <div class="lateral-menu-content column between flex1">
        <nav>
          <ul class="column need-mT2">
            <router-link to="/user/my-wine-cellar" class="nav--item">
              <span class="icon icon-cave-select need-mR-5"></span>
              Cave
            </router-link>
            <router-link to="/user/account" class="nav--item">
              <span class="icon icon-profil-select need-mR-5"></span>
              Profil
            </router-link>
            <router-link to="/user/subscription" class="nav--item"
              :class="$route.fullPath == '/user/subscription/update' ? 'sub-active' : ''">
              <span class="icon icon-money need-mR-5"></span>
              Abonnement
            </router-link>
          </ul>
        </nav>

        <div class="need-mL15 need-mT4 need-mB2">
          <p class="uppercase bold fontsize-8 need-mB2">En plus</p>
          <nav>
            <a class="link row align-center need-mB2" href="mailto:info@cote-vins.com">
              <span class="icon bold fontsize11 icon-chat need-mR-5"></span>
              <span class="semibold">Contacter par mail</span>
            </a>
            <button class="link row align-center" @click="openLogout()">
              <span class="icon bold fontsize12 icon-logout need-mR-5"></span>
              <span class="semibold">Se déconnecter</span>
            </button>
          </nav>
        </div>
      </div>
    </section>
  </header>
</template>

<script setup>
import { usePopup } from '@/composables/materials/usePopup'
import { ref } from "vue"


const popup = usePopup()
const  openMenuMobile = ref(false)


const openLogout = () => popup.open('LogoutPopup')

const toggleMenuMobile = () => openMenuMobile.value = !openMenuMobile.value



</script>