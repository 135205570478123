import { useAuthStore } from '@/pinia/authStore'
import { useUserStore } from '@/pinia/userStore'
import { useAdminStore } from '@/pinia/adminStore'
import { useProductStore } from '@/pinia/productStore'
import { useCategoryStore } from '@/pinia/categoryStore'
// import { useArrivalStore } from '@/pinia/arrivalStore'
import { useArrival } from '@/composables/app/useArrival'

export const useMiddleware = () => {

  const auth = () => {
    const auth = useAuthStore()

    if (!auth.isLogged())
      return false
  }

  const validation = async () => {
    const auth = useAuthStore()

    if (auth.user.role !== "PendingValidation")
      return false
  }

  const activation = async () => {
    const auth = useAuthStore()
    const user = useUserStore()

    if (auth.user.role !== "PendingActivation")
      return false

    await user.getPreferences()
  }

  const user = async (to, from) => {
    const auth = useAuthStore()
    const user = useUserStore()

    if (auth.user.role !== "User")
      return false

    if (!user.hasData)
      await user.getData()

    /**
     * préférences : l'ordre est important !
     */
    // si l'utilisateur est activé et envoie ses prefs
    if (from.name === "UserPreferences" && to.name === "UserAccount")
      await user.getData()

    // si l'utilisateur n'est pas encore activé et envoie ses prefs
    if (!user.hasPreferences && to.name !== "UserPreferences")
      return { name: "UserPreferences" }
  }

  const refreshUser = async () => {
    const user = useUserStore()
    await user.getData()
  }

  const admin = async () => {
    const auth = useAuthStore()
    const admin = useAdminStore()

    if (auth.user.role !== "Admin")
      return false

    if (!admin.hasData)
      await admin.getData()
  }

  const needUserData = async (to) => {
    const { user, setUser } = useAdminStore()
    const { userId } = to.params

    if (!user || user.data.userId != userId) {
      await setUser(userId)
    }
  }

  const needProductData = async (to) => {
    const { product, setProduct } = useProductStore()
    const { productId } = to.params

    if (!product || product.productId !== productId) {
      await setProduct(productId)
    }
  }

  // Doit être appliqué pour toutes les pages
  // où le categoryStore ou le productStore sont employés
  const resetProductSearchData = async () => {
    const { resetCategoryStore } = useCategoryStore()
    const { resetProductStore } = useProductStore()
    resetCategoryStore()
    resetProductStore()
  }

  const needArrivalData = async () => {
    const { getData } = useArrival()
    return await getData()
  }

  const MIDDLEWARES = {
    // GUEST
    AUTH: auth,
    VALIDATION: validation,
    ACTIVATION: activation,

    // USER
    USER: user,
    REFRESH_USER: refreshUser,

    // ADMIN
    ADMIN: admin,
    NEED_USER_DATA: needUserData,
    NEED_PRODUCT_DATA: needProductData,
    RESET_PRODUCT_SEARCH_DATA: resetProductSearchData,
    NEED_ARRIVAL_DATA: needArrivalData,
  }

  return {
    MIDDLEWARES
  }
}