import { defineStore } from 'pinia'
import { ref } from 'vue'

import api from '@/composables/api/useApi'
import router from '@/router'

import { datadogRum } from '@datadog/browser-rum'
import { useRequest } from '@/composables/api/useRequest'
import { useStorage } from '@/composables/api/useStorage'
import { useUserStore } from '@/pinia/userStore'
import { useAdminStore } from '@/pinia/adminStore'

export const useAuthStore = defineStore('auth', () => {

  const user = ref(initUser())
  
  function handleUserData(response) {
    const storage = useStorage()
  
    // store user data
    user.value = response.data
    storage.setUser(user.value)
    
     // handle role data
     const role = decodeJWT(response.data.token).role
    user.value.role = role
    if (window.VUE_ENABLE_DDOG == "true") {
      datadogRum.setUser({
        id: user.value.userId,
        name: `${user.value.lastname} ${user.value.firstname}`,
        role: role,
      })
    }


   
  }

  // ------------------------------------------------

  // user register
  async function register(form) {
    try {
      const newUser = await useRequest(api.post("users", form))
      handleUserData(newUser)

      router.push("/validation")
    }
    catch (error) {
      console.error('[AUTH ERROR] register -', error)
      return Promise.reject(error)
    }
  }

  async function validateWithCode(validationCode) {
    try {
      const validatedUser = await useRequest(api.post("validation", validationCode))
      handleUserData(validatedUser)
      console.log('ok')
      router.push('/activation')
    }
    catch (error) {
      console.error('[AUTH ERROR] validate -', error)
      return Promise.reject(error)
    }
  }

  async function validateWithLink({userId, validationCode}) {
    try {
      const validatedUser = await useRequest(api.post(`validation/${userId}`, { validationCode: validationCode }))
      handleUserData(validatedUser)

      if(user.value.role === "PendingActivation"){
        router.push('/activation')
      }
      else {
        //router.push('/user/account')
        router.push('/user/welcome')
      }

    }
    catch(error){
      return Promise.reject(error)
    }
  }

  // log user in
  async function login(credentials) {
    try {
      // logging in
      const loggedUser = await useRequest(api.post('authentication', credentials))
      handleUserData(loggedUser)

      // redirection
      let redirectUrl
      switch (user.value.role) {
        case "User": redirectUrl = "/user/welcome"
          break;
        case "Admin": redirectUrl = "/admin/demandes"
          break;
        case "PendingValidation": redirectUrl = "/validation"
          break;
        case "PendingActivation": redirectUrl = "/activation"
          break;
        default: redirectUrl = "/";
      }
      router.push(redirectUrl)

      // middleware triggered after redirection
      // ~> @/router/middlewares.js 
    }
    catch (error) {
      console.error('[AUTH ERROR] login -', error)
      return Promise.reject(error)
    }
  }

  // refresh user token
  async function refresh() {
    console.warn('Need to refresh token ...')
    const refreshResponse = await useRequest(api.post('authentication/refresh'))
    handleUserData(refreshResponse)
  }

  // log user out
  async function logout() {
    try {
      await useRequest(api.post('authentication/logout'))
      resetAuth()
      router.push('/')
    }
    catch (error) {
      console.error('[AUTH ERROR] logout -', error)
      router.push('/')
      Promise.reject(error)
    }
  }

  function resetAuth() {
    const storage = useStorage()

    user.value = null
    storage.removeUser()

    const userStore = useUserStore()
    userStore.resetStore()

    const adminStore = useAdminStore()
    adminStore.resetStore()
  }

  // GETTERS
  function isLogged() {
    return user.value ? true : false
  }

  return {
    user,
    isLogged,
    register,
    validateWithCode,
    validateWithLink,
    login,
    refresh,
    logout,
  }
})

function initUser(retry = true) {
  const storage = useStorage()

  try {
    let data = null
    const storedUser = storage.getUser()
    data = JSON.parse(storedUser)
    data.role = decodeJWT(JSON.parse(storedUser).token).role
    return data
  }
  catch (e) {
    storage.removeUser()
    if (!retry) initUser(false)
    else return null
  }
}

function decodeJWT(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}